<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '80px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="显示类型" name="showType">
              <a-select v-model:value="search.data.showType" placeholder="请选择成员身份" allowClear>
                <a-select-option :value="1">只看代理</a-select-option>
                <a-select-option :value="2">查看所有</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="memberMaskId">
              <a-input v-model:value.number="search.data.memberMaskId" placeholder="请输入成员游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <!-- <a-col v-bind="searchCol">
            <a-form-item label="userId" name="memberId">
              <a-input v-model:value.number="search.data.memberId" placeholder="请输入成员userId" allowClear> </a-input>
            </a-form-item>
          </a-col> -->
        </l-search-container>

        <!-- <a-col v-bind="searchCol">
            <a-form-item label="成员身份" name="memberIdentity">
              <a-select v-model:value="search.data.memberIdentity" placeholder="请选择成员身份" allowClear>
                <a-select-option :value="item.value" v-for="item in clubMemberIdentitys" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="是否失联" name="isDisconn">
              <a-select v-model:value="search.data.isDisconn" placeholder="请选择是否失联" allowClear>
                <a-select-option :value="0">正常</a-select-option>
                <a-select-option :value="1">已失联</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
      </a-form>
    </template>

    <l-table :show-action-icon="false" :pagination="false" @reload="onSearch" :columns="columns" :data-source="data" row-key="memberId" :loading="loading" v-if="data.length > 0" v-model:expanded-row-keys="expandedRowKeys" :indentSize="15" @expand="handleExpanded" :offset-height="350" defaultTableSize="small">
      <!-- <template v-slot:toolbar-action>
        <l-auth-button auth="club_member_create_btn" type="primary" @click="showMemberAdd">
          <PlusOutlined />
          绑定代理/成员
        </l-auth-button>
      </template> -->

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'memberIcon'">
          <a-avatar :size="30" :src="record.memberIcon"> </a-avatar>
        </template>

        <template v-if="column.dataIndex === 'isValid'">
          <a-tag :color="record.isValid == 1 ? 'success' : 'error'">{{ record.isValid == 1 ? "有效" : "无效" }}</a-tag>
        </template>

        <template v-if="column.dataIndex === 'isOnline'">
          <a-tag :color="record.isOnline == 1 ? 'success' : 'error'">{{ record.isOnline == 1 ? "在线" : "不在线" }}</a-tag>
        </template>

        <template v-if="column.dataIndex === 'isDisconn'">
          <a-tag v-if="record.isDisconn === 1" color="error">流失</a-tag>
          <a-tag v-if="record.isDisconn === 0" color="success">正常</a-tag>
        </template>

        <template v-if="column.dataIndex === 'hpCoins'">
          <template v-if="record.hpCoins !== null">
            <span class="l-font-success l-bold" v-show="record.hpCoins >= 0">{{ record.hpCoins }}</span>
            <span class="l-font-danger l-bold" v-show="record.hpCoins < 0">{{ record.hpCoins }}</span>
          </template>
        </template>

        <template v-if="column.dataIndex === 'memberMaskId'">
          <div style="float: left">
            <div>
              <span class="l-font-danger l-bold" v-if="record.memberMaskId == search.relData.memberMaskId"> ID：{{ record.memberMaskId }} </span>
              <span v-else> ID：{{ record.memberMaskId }} </span>
            </div>
            <div>昵称:{{ record.memberName }}</div>
            <div>身份:【{{ getLabelByValue(clubMemberIdentitys, record.memberIdentity) }}】</div>
            <div>等级:{{ record.memberLevel }}</div>
            <!-- <div>初始体力:{{ record.initHp }}</div>
            <div>
              当前体力:
              <template v-if="record.hpCoins !== null">
                <span class="l-font-success l-bold" v-show="record.hpCoins >= 0">{{ record.hpCoins }}</span>
                <span class="l-font-danger l-bold" v-show="record.hpCoins < 0">{{ record.hpCoins }}</span>
              </template>
            </div> -->
            <!-- <div>
              <a-avatar :size="30" :src="record.memberIcon" style="margin-right: 5px"> </a-avatar>
            </div> -->
          </div>
        </template>

        <template v-if="column.dataIndex === 'memberId'">
          <div>
            <span class="l-font-danger l-bold" v-if="record.memberId == search.relData.memberId">
              {{ record.memberId }}
            </span>
            <span v-else>
              {{ record.memberId }}
            </span>
          </div>
        </template>

        <template v-if="column.dataIndex === 'memberIdentity'">
          {{ getLabelByValue(clubMemberIdentitys, record.memberIdentity) }}
        </template>

        <template v-if="column.dataIndex === 'action'">
          <!-- <l-auth-text-button auth="club_member_upd_identity_btn" type="warning" @click="showUpdIdentity(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
            <RollbackOutlined />
            更改身份
          </l-auth-text-button>

          <l-auth-text-button auth="club_member_bind_change_btn" type="danger" danger @click="showBindChange(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
            <RetweetOutlined />
            关系转移
          </l-auth-text-button>

          <l-auth-text-button auth="club_member_create_btn" type="primary" primary @click="showMemberAdd(record)" :disabled="record.memberIdentity == ClubMemberIdentityMember">
            <LinkOutlined />
            绑定下级代理/成员
          </l-auth-text-button>

          <l-auth-text-button type="danger" auth="club_member_unbind_btn" primary @click="del(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
            <DeleteFilled />
            解除绑定
          </l-auth-text-button> -->

          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              操作
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <l-auth-text-button auth="club_member_upd_identity_btn" type="warning" @click="showUpdIdentity(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
                    <RollbackOutlined />
                    更改身份
                  </l-auth-text-button>
                </a-menu-item>
                <a-menu-item>
                  <l-auth-text-button auth="club_member_bind_change_btn" type="danger" danger @click="showBindChange(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
                    <RetweetOutlined />
                    关系转移
                  </l-auth-text-button>
                </a-menu-item>
                <a-menu-item>
                  <l-auth-text-button auth="club_member_create_btn" type="primary" primary @click="showMemberAdd(record)" :disabled="record.memberIdentity == ClubMemberIdentityMember">
                    <LinkOutlined />
                    绑定下级代理/成员
                  </l-auth-text-button>
                </a-menu-item>
                <a-menu-item>
                  <l-auth-text-button type="danger" auth="club_member_unbind_btn" primary @click="del(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
                    <DeleteFilled />
                    解除绑定
                  </l-auth-text-button>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </template>
    </l-table>

    <a-modal v-model:visible="visible" title="绑定代理/成员" @cancel="visible = false" destroyOnClose>
      <BindMember :club-id="clubId" :up-member-mask-id="upMemberMaskId" v-model:visible="visible" @close="visible = false" @success="getList"></BindMember>
    </a-modal>

    <a-modal v-model:visible="bindChVisible" title="关系转移" @cancel="bindChVisible = false" destroyOnClose>
      <BindChange :club-id="clubId" :up-member-mask-id="bcupMemberMaskId" :up-member-id="bcupMemberId" :member-mask-id="bcMemberMaskId" :member-id="bcMemberId" v-model:visible="bindChVisible" @close="bindChVisible = false" @success="getList"></BindChange>
    </a-modal>

    <a-modal v-model:visible="updIdentityVisible" title="更改身份" width="700px" centered destroyOnClose @cancel="updIdentityVisible = false">
      <template #footer>
        <a-button key="back" @click="updIdentityVisible = false">关闭</a-button>
      </template>
      <UpdIdentity @close="updIdentityVisible = false" :club-id="updIdentityClubId" :member-mask-id="updIdentityMemeberId" @success="getList" />
    </a-modal>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { ClubMemberIdentityMember, ClubMemberIdentityPresident, clubMemberIdentitys, getLabelByValue } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { searchCol } from "@/config/app";
import { message } from "ant-design-vue";
import { showConfirm } from "@/common/utils";
import BindMember from "./BindMember.vue";
import BindChange from "./BindChange.vue";
import UpdIdentity from "./UpdIdentity.vue";

const columns = [
  {
    title: "成员",
    width: 400,
    dataIndex: "memberMaskId",
    ellipsis: true,
    // fixed: "left",
  },
  // {
  //   title: "成员userId",
  //   width: 100,
  //   dataIndex: "memberId",
  // },

  // {
  //   title: "成员身份",
  //   width: 80,
  //   dataIndex: "memberIdentity",
  // },

  // {
  //   title: "成员等级",
  //   width: 80,
  //   dataIndex: "memberLevel",
  // },

  // {
  //   title: "头像",
  //   width: 80,
  //   dataIndex: "memberIcon",
  //   ellipsis: true,
  // },

  // {
  //   title: "昵称",
  //   width: 120,
  //   dataIndex: "memberName",
  //   ellipsis: true,
  // },

  {
    title: "初始体力值",
    width: 100,
    dataIndex: "initHp",
    ellipsis: true,
  },

  {
    title: "当前体力值",
    width: 100,
    dataIndex: "hpCoins",
    ellipsis: true,
  },

  // {
  //   title: "是否有效",
  //   width: 80,
  //   dataIndex: "isValid",
  //   ellipsis: true,
  // },

  {
    title: "是否在线",
    width: 80,
    dataIndex: "isOnline",
    ellipsis: true,
  },

  {
    title: "是否流失",
    width: 80,
    dataIndex: "isDisconn",
    ellipsis: true,
  },

  // {
  //   title: "上级成员userID",
  //   width: 120,
  //   dataIndex: "upMemberId",
  // },

  // {
  //   title: "上级成员游戏ID",
  //   width: 120,
  //   dataIndex: "upMemberMaskId",
  // },

  // {
  //   title: "上级成员昵称",
  //   width: 100,
  //   dataIndex: "upMemberName",
  // },

  {
    title: "加入时间",
    width: 180,
    dataIndex: "joinTime",
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 70,
  },
];

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
  },
  components: {
    BindMember,
    BindChange,
    UpdIdentity,
  },
  setup(props) {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        showType: 1,
        memberMaskId: undefined,
        memberId: undefined,
        memberIdentity: undefined,
        isDisconn: undefined,
      },

      //点击搜索后的搜索数据
      relData: {
        showType: 1,
        memberMaskId: undefined,
        memberId: undefined,
        memberIdentity: undefined,
        isDisconn: undefined,
      },
    });

    //表格加载中
    const loading = ref(true);

    const data = ref([]);
    const expandedRowKeys = ref<any>([]);

    const upMemberMaskId = ref(0);

    const formRef = ref();

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //是否显示关系转移
    const bindChVisible = ref(false);
    const bcupMemberMaskId = ref(0);
    const bcupMemberId = ref(0);
    const bcMemberMaskId = ref(0);
    const bcMemberId = ref(0);

    const defaultExpandAllRows = ref(false);

    const updIdentityVisible = ref(false);
    const updIdentityMemeberId = ref(0);
    const updIdentityClubId = ref(0);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get(`us_club/${props.clubId}/member_tree`, {
        ...search.relData,
      });

      data.value = result.data.treeData;

      // expandedRowKeys.value = result.data.expendIds;

      loading.value = false;
    };

    //点击编辑按钮
    const showMemberAdd = (row: any) => {
      upMemberMaskId.value = row.memberMaskId;
      nextTick(() => {
        visible.value = true;
      });
    };

    //显示关系转移
    const showBindChange = (row: any) => {
      bcupMemberMaskId.value = row.upMemberMaskId;
      bcupMemberId.value = row.upMemberId;
      bcMemberId.value = row.memberId;
      bcMemberMaskId.value = row.memberMaskId;
      nextTick(() => {
        bindChVisible.value = true;
      });
    };

    //点击搜索按钮
    const onSearch = () => {
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //删除
    const del = (row: any) => {
      showConfirm({
        title: "解除确认",
        content: "将解除该成员，及其下级代理/成员和本俱乐部的绑定吗，确定解除吗?",
        onOk: async () => {
          await http.delete(`us_club/${props.clubId}/member/${row.memberId}`);
          getList();
          message.success("解绑成功");
        },
      });
    };

    //监听展开事件
    const handleExpanded = (expend: boolean, record: any) => {
      if (expend) {
        expandedRowKeys.value.push(record.memberId);
      } else {
        const index = expandedRowKeys.value.findIndex((item: any) => {
          return item == record.memberId;
        });
        expandedRowKeys.value.splice(index, 1);
      }
    };

    //点击显示更改成员身份
    const showUpdIdentity = (row: any) => {
      updIdentityMemeberId.value = row.memberMaskId;
      updIdentityClubId.value = props.clubId;
      nextTick(() => {
        updIdentityVisible.value = true;
      });
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      formRef,
      loading,
      clubMemberIdentitys,
      defaultExpandAllRows,
      showMemberAdd,
      upMemberMaskId,
      del,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      getList,
      expandedRowKeys,
      ClubMemberIdentityPresident,
      bindChVisible,
      bcupMemberMaskId,
      bcupMemberId,
      bcMemberId,
      bcMemberMaskId,
      ClubMemberIdentityMember,
      showBindChange,
      handleExpanded,
      updIdentityVisible,
      updIdentityMemeberId,
      updIdentityClubId,
      showUpdIdentity,
    };
  },
});
</script>
