<template>
  <div>
    <l-form :rules="rules" :model="formState" ref="formRef">
      <a-spin :spinning="loading">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="所属俱乐部">
              <div>
                <span class="l-font-num">{{ clubData.clubId }}</span> -- {{ clubData.clubName }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="原上级代理">
              <div>
                <span class="l-font-num">{{ upMemberMaskId }}</span> -- {{ upMemberData.nick_name }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="要转移到的上级代理" name="newUpMemberMaskId">
              <a-tree-select
                v-model:value="formState.newUpMemberMaskId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="agentList"
                placeholder="搜索游戏ID/昵称"
                :field-names="{ key: 'memberMaskId', value: 'memberMaskId' }"
                tree-default-expand-all
                treeNodeFilterProp="title"
                show-search
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="24">
            <a-form-item label="成员身份" name="memberIdentity">
              <a-select v-model:value="formState.memberIdentity" placeholder="请选择成员身份">
                <a-select-option :value="item.value" v-for="item in clubMemberSelAbleIdentitys" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-spin>

      <a-alert type="error">
        <template #message> 注意：转移后，该代理整条线下的<span class="l-font-danger l-bold">规则比例将会清零</span>，需要重新设置 </template>
      </a-alert>

      <div class="l-drawer-edit-footer">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :disabled="loading" :loading="submitLoading">提交</a-button>
      </div>
    </l-form>
  </div>
</template>

<script lang="ts">
import { clubMemberIdentitys, clubMemberSelAbleIdentitys, getLabelByValue, paltformTypes, sexTypes } from "@/common/head";
import http from "@/common/http";
import { treeDataFormat } from "@/common/utils";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
    upMemberMaskId: {
      type: Number,
      required: true,
    },
    upMemberId: {
      type: Number,
      required: true,
    },

    memberMaskId: {
      type: Number,
      required: true,
    },
    memberId: {
      type: Number,
      required: true,
    },
  },
  components: {},
  setup(props, { emit }) {
    const clubData = ref({
      clubName: "",
      clubId: "",
    });

    const upMemberData = ref({
      nick_name: "",
    });

    const memberData = ref({
      nick_name: "",
    });

    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();
    const agentList = ref<any>([]);

    const submitLoading = ref(false);

    const formState = ref<any>({
      upMemberMaskId: props.upMemberMaskId,
      memberMaskId: props.memberMaskId,
      newUpMemberMaskId: undefined,
      // memberIdentity: undefined,
    });

    //验证规则
    const rules = {
      newUpMemberMaskId: [{ required: true, message: "请输入上级代理游戏ID", trigger: "change", type: "number" }],
      memberIdentity: [{ required: true, message: "请选择身份", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("close");
    };

    //获取俱乐部信息
    const getClubData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_club/${props.clubId}`, formState.value);
      clubData.value = data.formData;
      loading.value = false;
    };

    //获取上级信息
    const getUpMemberData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_user/mask_id/${props.upMemberMaskId}`, formState.value);
      upMemberData.value = data.data;
      loading.value = false;
    };

    //获取非当前代理及其下级的代理
    const getAgentList = async () => {
      loading.value = true;
      const { data } = await http.get(`us_club/${props.clubId}/ch_bind_agent_list/${props.memberId}`, formState.value);
      agentList.value = data.treeData;
      //格式化显示内容
      agentList.value = treeDataFormat(agentList.value, (item: any) => {
        item.title = item.memberMaskId + " - " + item.memberName + " - " + getLabelByValue(clubMemberIdentitys, item.memberIdentity);
        // item.slots = {
        //   title: "title",
        // };
        return item;
      });
      loading.value = false;
    };

    onMounted(() => {
      getClubData();
      getUpMemberData();
      getAgentList();
    });

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;

        try {
          //保存
          await http.post(`us_club/${props.clubId}/change_bind`, formState.value);
          message.success("保存成功");
          submitLoading.value = false;
          onClose();
          emit("success");
        } catch (e) {
          submitLoading.value = false;
        }
      });
    };

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      paltformTypes,
      sexTypes,
      clubData,
      upMemberData,
      memberData,
      onClose,
      handleSubmit,
      clubMemberSelAbleIdentitys,
      agentList,
    };
  },
});
</script>
