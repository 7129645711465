<template>
  <div>
    <a-card title="玩法列表" hoverable :body-style="{ padding: '5px 15px' }">
      <l-table :show-action-icon="false" @reload="onSearch" :columns="columns" :data-source="data" :pagination="false" row-key="id" :loading="loading" :scroll="{ x: 1500, y: 300 }" defaultTableSize="small">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'ruleName'">
            <div>{{ record.ruleName }}</div>
            <div>
              <l-auth-text-button auth="us_club_rule_show_btn" type="danger" primary @click="getPercentList(record)"> 点击查看比例分配 </l-auth-text-button>
            </div>
          </template>

          <template v-if="column.dataIndex === 'status'">
            <a-tag color="success" v-if="record.status == '1'">启用</a-tag>
            <a-tag color="error" v-if="record.status == '0'">禁用</a-tag>
          </template>

          <template v-if="column.dataIndex === 'gameType'">
            {{ getLabelByValue(gameTypes, record.gameType) }}
          </template>

          <template v-if="column.dataIndex === 'ruleStr'">
            <a-button type="link" @click="showRuleStr(record)" style="padding-left: 0; margin-left: 0">点击查看规则内容</a-button>
          </template>

          <template v-if="column.dataIndex === 'action'">
            <l-auth-text-button auth="us_club_rule_upd_state_btn" type="warning" primary @click="showUpdState(record)">
              <EditFilled />
              更改状态
            </l-auth-text-button>
          </template>
        </template>
      </l-table>
    </a-card>

    <a-card style="margin-top: 10px" hoverable :body-style="{ padding: '5px 15px' }">
      <template #title>
        <span class="l-font-primary">{{ percentData.ruleName }}</span>
        的代理分配比例
      </template>

      <div class="l-no-form-item-mg">
        <a-form ref="formRef" :model="memberSearch.data" :labelCol="{ style: { width: '100px' } }">
          <l-search-container @search="onMemberSearch" @reset="onMemberReset">
            <a-col v-bind="searchCol">
              <a-form-item label="成员游戏ID" name="memberMaskId">
                <a-input v-model:value.number="memberSearch.data.memberMaskId" placeholder="请输入成员游戏ID" allowClear> </a-input>
              </a-form-item>
            </a-col>
            <!-- <a-col v-bind="searchCol">
              <a-form-item label="成员userId" name="memberId">
                <a-input v-model:value.number="memberSearch.data.memberId" placeholder="请输入成员userId" allowClear> </a-input>
              </a-form-item>
            </a-col> -->
          </l-search-container>
        </a-form>
      </div>

      <l-table :show-action-icon="false" :pagination="false" @reload="onUpdPercentSuccess" :columns="percentColumns" :data-source="percentData.data" row-key="memberId" :loading="percentData.loading" v-model:expanded-row-keys="percentData.expandedRowKeys" :indentSize="10" @expand="handleExpanded" :offset-height="450" defaultTableSize="small">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'memberIcon'">
            <a-avatar :size="30" :src="record.memberIcon"> </a-avatar>
          </template>

          <template v-if="column.dataIndex === 'memberMaskId'">
            <div style="float: left">
              <div>
                <span class="l-font-danger l-bold" v-if="record.memberMaskId == memberSearch.relData.memberMaskId"> ID:{{ record.memberMaskId }} </span>
                <span v-else> ID:{{ record.memberMaskId }} </span>
              </div>
              <div>昵称:{{ record.memberName }}</div>
              <!-- <div>身份:【{{ getLabelByValue(clubMemberIdentitys, record.memberIdentity) }}】</div> -->
              <div>等级:{{ record.memberLevel }}</div>
            </div>
          </template>

          <template v-if="column.dataIndex === 'memberId'">
            <span class="l-font-danger l-bold" v-if="record.memberId == memberSearch.relData.memberId">
              {{ record.memberId }}
            </span>
            <span v-else>
              {{ record.memberId }}
            </span>
          </template>

          <template v-if="column.dataIndex === 'memberIdentity'">
            {{ getLabelByValue(clubMemberIdentitys, record.memberIdentity) }}
          </template>

          <template v-if="column.dataIndex === 'percent'">
            <template v-if="record.memberIdentity != ClubMemberIdentityPresident">
              <span v-show="record.percent === null" class="l-font-danger">未设置</span>
              <span v-show="record.percent !== null" class="l-font-primary l-bold">{{ record.percent }} %</span>
            </template>
            <template v-else> -- </template>
          </template>

          <template v-if="column.dataIndex === 'action'">
            <l-auth-text-button auth="club_allocation_upd_percent_btn" type="primary" primary @click="showUpdPercent(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
              <EditFilled />
              修改比例
            </l-auth-text-button>

            <l-auth-text-button type="danger" auth="club_allocation_clear_rule_btn" primary @click="clearRule(record)" :disabled="record.memberIdentity == ClubMemberIdentityPresident">
              <DeleteFilled />
              清除玩法
            </l-auth-text-button>
          </template>
        </template>
      </l-table>
    </a-card>

    <a-modal v-model:visible="updStateVisible" title="修改状态" @cancel="updStateVisible = false" destroyOnClose>
      <ChangeRuleState @close="updStateVisible = false" v-model:id="editId" @success="onUpdSuccess" />
    </a-modal>

    <a-modal v-model:visible="updPercentVisible" title="修改比例" @cancel="updPercentVisible = false" destroyOnClose>
      <UpdPercent @close="updPercentVisible = false" v-model:member-id="updPercentMemberId" :rule-id="percentData.ruleId" @success="onUpdPercentSuccess" />
    </a-modal>

    <a-modal v-model:visible="ruleVisible" title="规则内容" width="900px" centered destroyOnClose :bodyStyle="{ padding: '0' }" @cancel="ruleVisible = false">
      <template #footer>
        <a-button key="back" @click="ruleVisible = false">关闭</a-button>
      </template>
      <RuleInfo @close="ruleVisible = false" :rule-str="currRuleStr" />
    </a-modal>
  </div>
</template>

<script lang="ts">
import http from "@/common/http";
import { ClubMemberIdentityPresident, clubMemberIdentitys, gameTypes, getLabelByValue, sexTypes } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import ChangeRuleState from "./ChangeRuleState.vue";
import RuleInfo from "./RuleInfo.vue";
import UpdPercent from "./UpdPercent.vue";

import { showConfirm } from "@/common/utils";
import { message } from "ant-design-vue";
// import { showConfirm } from "@/common/utils";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ChangeRuleState,
    UpdPercent,
    RuleInfo,
  },
  setup(props) {
    const columns = ref([
      {
        title: "规则名称",
        width: 140,
        dataIndex: "ruleName",

        // fixed: "left",
      },

      {
        title: "规则内容",
        width: 140,
        name: "ruleStr",
        key: "ruleStr",
        dataIndex: "ruleStr",
      },

      {
        title: "状态",
        width: 90,
        dataIndex: "status",
        ellipsis: true,
      },

      {
        title: "游戏类型",
        width: 90,
        dataIndex: "gameType",
        ellipsis: true,
      },

      {
        title: "服务类型",
        width: 90,
        dataIndex: "servType",
        ellipsis: true,
      },

      {
        title: "创建人userId",
        width: 120,
        dataIndex: "createId",
        ellipsis: true,
      },

      {
        title: "创建人游戏ID",
        width: 120,
        dataIndex: "createMaskId",
        ellipsis: true,
      },

      {
        title: "创建人昵称",
        width: 120,
        dataIndex: "createName",
        ellipsis: true,
      },

      {
        title: "创建时间",
        width: 180,
        dataIndex: "createTime",
        ellipsis: true,
      },

      {
        title: "更新时间",
        width: 180,
        dataIndex: "updTime",
        ellipsis: true,
      },

      {
        title: "备注",
        width: 180,
        dataIndex: "remark",
        ellipsis: true,
      },

      {
        title: "操作",
        align: "center",
        key: "action",
        dataIndex: "action",
        // fixed: "right",
        width: 200,
      },
    ]);

    //搜索成员数据
    const memberSearch = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        memberMaskId: undefined,
        memberId: undefined,
      },

      //点击搜索后的搜索数据
      relData: {
        memberMaskId: undefined,
        memberId: undefined,
      },
    });

    //比例表列信息
    const percentColumns = ref([
      {
        title: "成员",
        width: 400,
        dataIndex: "memberMaskId",
        ellipsis: true,
        // fixed: "left",
      },

      {
        title: "分配比例",
        width: 100,
        dataIndex: "percent",
      },

      // {
      //   title: "成员userId",
      //   width: 100,
      //   dataIndex: "memberId",
      // },

      // {
      //   title: "成员身份",
      //   width: 80,
      //   dataIndex: "memberIdentity",
      // },

      // {
      //   title: "成员等级",
      //   width: 80,
      //   dataIndex: "memberLevel",
      // },

      // // {
      // //   title: "头像",
      // //   width: 80,
      // //   dataIndex: "memberIcon",
      // //   slots: { customRender: "memberIcon" },
      // //   ellipsis: true,
      // // },

      // {
      //   title: "昵称",
      //   width: 120,
      //   dataIndex: "memberName",
      //   ellipsis: true,
      // },

      {
        title: "操作",
        align: "center",
        key: "action",
        dataIndex: "action",
        // fixed: "right",
        width: 120,
      },
    ]);

    const percentData = reactive<any>({
      data: [], //代理比例数据
      loading: false, //是否加载中
      expandedRowKeys: [], //展开的代理数据
      ruleId: undefined, //当前选中的规则ID
      ruleName: "", //当前选中的规则名称
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const formRef = ref();

    const updStateVisible = ref(false);
    const editId = ref(0);

    const currRuleStr = ref("");
    const ruleVisible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get(`us_club/${props.clubId}/rule`, {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...memberSearch.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //获取比例列表
    const getPercentList = async (record: any) => {
      percentData.ruleId = record.id;
      percentData.ruleName = record.ruleName;
      percentData.loading = true;
      const result = await http.get(`us_club_rule/${record.id}/us_club_rule_allocation`, {
        ...memberSearch.relData,
      });

      percentData.data = result.data.treeData;
      percentData.expandedRowKeys = result.data.expendIds;

      percentData.loading = false;
    };

    //点击更改状态按钮
    const showUpdState = (row: any) => {
      editId.value = row.id;
      updStateVisible.value = true;
    };

    //点击搜索按钮
    const onSearch = () => {
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      onSearch();
    };

    //点击分配比例搜索按钮
    const onMemberSearch = () => {
      memberSearch.relData = memberSearch.data;
      onUpdPercentSuccess();
    };

    //点击成员搜索重置按钮
    const onMemberReset = () => {
      formRef.value.resetFields();
      memberSearch.relData = {
        memberMaskId: undefined,
        memberId: undefined,
      };
      onUpdPercentSuccess();
    };

    //新增/修改成功
    const onUpdSuccess = () => {
      editId.value = 0;
      getList();
    };

    //监听展开事件
    const handleExpanded = (expend: boolean, record: any) => {
      if (expend) {
        percentData.expandedRowKeys.push(record.memberId);
      } else {
        const index = percentData.expandedRowKeys.findIndex((item: any) => {
          return item == record.memberId;
        });
        percentData.expandedRowKeys.splice(index, 1);
      }
    };

    //显示修改比例弹窗
    const updPercentVisible = ref(false);
    const updPercentMemberId = ref(0);
    const showUpdPercent = (row: any) => {
      updPercentMemberId.value = row.memberId;
      nextTick(() => {
        updPercentVisible.value = true;
      });
    };

    //修改比例成功
    const onUpdPercentSuccess = () => {
      getPercentList({
        id: percentData.ruleId,
        ruleName: percentData.ruleName,
      });
    };

    //搜索

    onMounted(async () => {
      await getList();

      if (data.value.length > 0) {
        await getPercentList(data.value[0]);
      }
    });

    //清除玩法
    const clearRule = (row: any) => {
      showConfirm({
        title: "清除确认",
        content: `注意，本操作将清除##${row.memberMaskId}##及其所有下级代理的《${percentData.ruleName}》玩法（即将本玩法的比例设置为0），确定要清除吗？`,
        onOk: async () => {
          await http.delete(`us_club_rule/${percentData.ruleId}/member/${row.memberId}/us_club_rule_allocation/percent`);
          onUpdPercentSuccess();
          message.success("清除成功");
        },
      });
    };

    //点击显示规则内容
    const showRuleStr = (row: any) => {
      currRuleStr.value = row.ruleStr;
      ruleVisible.value = true;
    };

    return {
      data,
      columns,
      memberSearch,
      pagination,
      edit_ref,
      formRef,
      editId,
      loading,
      updStateVisible,
      onSearch,
      onReset,
      searchCol,
      sexTypes,
      getLabelByValue,
      onUpdSuccess,
      showUpdState,
      gameTypes,
      percentColumns,
      percentData,
      handleExpanded,
      clubMemberIdentitys,
      getPercentList,
      showUpdPercent,
      updPercentVisible,
      updPercentMemberId,
      ClubMemberIdentityPresident,
      onUpdPercentSuccess,
      clearRule,
      onMemberSearch,
      onMemberReset,
      showRuleStr,
      currRuleStr,
      ruleVisible,
    };
  },
});
</script>
