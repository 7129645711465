
import { clubMemberSelAbleIdentitys, paltformTypes, sexTypes, getLabelByValue, ClubMemberIdentityAgent, ClubMemberIdentityMember } from "@/common/head";
import http from "@/common/http";
import { message } from "ant-design-vue";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
    memberMaskId: {
      type: Number,
      required: true,
    },
  },
  components: {},
  setup(props, { emit }) {
    const memberData = ref({
      memberName: "",
      memberIdentity: -1,
    });

    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();

    const submitLoading = ref(false);

    const fileList = ref<any[]>([]);
    const formState = ref<any>({
      memberMaskId: props.memberMaskId,
      memberIdentity: undefined,
    });

    //验证规则
    const rules = {
      maskId: [{ required: true, message: "请输入玩家游戏ID", trigger: "change", type: "number" }],
      memberIdentity: [{ required: true, message: "请选择身份", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("close");
    };

    //获取更改身份的信息
    const getMemberData = async () => {
      const { data } = await http.get(`us_club/${props.clubId}/member/${props.memberMaskId}/upd_identity`, formState.value);
      memberData.value = data.memberData;
    };

    //可选的成员身份
    const identityList = computed(() => {
      return clubMemberSelAbleIdentitys.filter((item) => {
        //去掉当前的身份
        if (item.value == memberData.value.memberIdentity) {
          return false;
        }

        //如果是代理，只能变更成成员
        if (memberData.value.memberIdentity == ClubMemberIdentityAgent && item.value != ClubMemberIdentityMember) {
          return false;
        }

        return true;
      });
    });

    onMounted(() => {
      getMemberData();
    });

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;

        try {
          //保存
          await http.put(`us_club/${props.clubId}/member/${props.memberMaskId}/upd_identity`, formState.value);
          message.success("修改成功");
          submitLoading.value = false;
          onClose();
          emit("success");
        } catch (e) {
          submitLoading.value = false;
        }
      });
    };

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      paltformTypes,
      fileList,
      sexTypes,
      memberData,
      identityList,
      clubMemberSelAbleIdentitys,
      onClose,
      handleSubmit,
      getMemberData,
      getLabelByValue,
    };
  },
});
