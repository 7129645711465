
import { clubMemberIdentitys, clubMemberSelAbleIdentitys, getLabelByValue, paltformTypes, sexTypes } from "@/common/head";
import http from "@/common/http";
import { treeDataFormat } from "@/common/utils";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
    upMemberMaskId: {
      type: Number,
      required: true,
    },
    upMemberId: {
      type: Number,
      required: true,
    },

    memberMaskId: {
      type: Number,
      required: true,
    },
    memberId: {
      type: Number,
      required: true,
    },
  },
  components: {},
  setup(props, { emit }) {
    const clubData = ref({
      clubName: "",
      clubId: "",
    });

    const upMemberData = ref({
      nick_name: "",
    });

    const memberData = ref({
      nick_name: "",
    });

    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();
    const agentList = ref<any>([]);

    const submitLoading = ref(false);

    const formState = ref<any>({
      upMemberMaskId: props.upMemberMaskId,
      memberMaskId: props.memberMaskId,
      newUpMemberMaskId: undefined,
      // memberIdentity: undefined,
    });

    //验证规则
    const rules = {
      newUpMemberMaskId: [{ required: true, message: "请输入上级代理游戏ID", trigger: "change", type: "number" }],
      memberIdentity: [{ required: true, message: "请选择身份", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("close");
    };

    //获取俱乐部信息
    const getClubData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_club/${props.clubId}`, formState.value);
      clubData.value = data.formData;
      loading.value = false;
    };

    //获取上级信息
    const getUpMemberData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_user/mask_id/${props.upMemberMaskId}`, formState.value);
      upMemberData.value = data.data;
      loading.value = false;
    };

    //获取非当前代理及其下级的代理
    const getAgentList = async () => {
      loading.value = true;
      const { data } = await http.get(`us_club/${props.clubId}/ch_bind_agent_list/${props.memberId}`, formState.value);
      agentList.value = data.treeData;
      //格式化显示内容
      agentList.value = treeDataFormat(agentList.value, (item: any) => {
        item.title = item.memberMaskId + " - " + item.memberName + " - " + getLabelByValue(clubMemberIdentitys, item.memberIdentity);
        // item.slots = {
        //   title: "title",
        // };
        return item;
      });
      loading.value = false;
    };

    onMounted(() => {
      getClubData();
      getUpMemberData();
      getAgentList();
    });

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;

        try {
          //保存
          await http.post(`us_club/${props.clubId}/change_bind`, formState.value);
          message.success("保存成功");
          submitLoading.value = false;
          onClose();
          emit("success");
        } catch (e) {
          submitLoading.value = false;
        }
      });
    };

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      paltformTypes,
      sexTypes,
      clubData,
      upMemberData,
      memberData,
      onClose,
      handleSubmit,
      clubMemberSelAbleIdentitys,
      agentList,
    };
  },
});
