
import http from "@/common/http";
import { getLabelByValue, ClubBusinessMode, clubBusinessStatus, gameTypes, ClubBusinessStatusOn, ClubBusinessStatusOff, nullDeskTypes, fullDeskTypes } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import Edit from "./Edit.vue";
import Info from "./Info.vue";
import { message, PaginationProps } from "ant-design-vue";
import { showConfirm } from "@/common/utils";

const columns = [
  // {
  //   title: "id",
  //   width: 90,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "俱乐部ID",
    width: 100,
    dataIndex: "clubId",
    ellipsis: true,
    fixed: "left",
  },

  {
    title: "俱乐部名称",
    width: 110,
    dataIndex: "clubName",
    ellipsis: true,
  },

  {
    title: "创建者userId",
    width: 110,
    dataIndex: "creatorId",
    ellipsis: true,
  },

  {
    title: "创建者游戏ID",
    width: 110,
    dataIndex: "creatorMaskId",
    ellipsis: true,
  },

  {
    title: "创建者昵称",
    width: 110,
    dataIndex: "creatorName",
    ellipsis: true,
  },

  {
    title: "创建者头像",
    width: 90,
    dataIndex: "creatorIcon",
    ellipsis: true,
  },

  {
    title: "当前用户数量",
    width: 110,
    dataIndex: "currNumb",
    ellipsis: true,
  },

  {
    title: "最大人数限制",
    width: 110,
    dataIndex: "maxLimitNumb",
    ellipsis: true,
  },

  {
    title: "是否营业",
    width: 90,
    dataIndex: "businessStatus",
    ellipsis: true,
  },

  {
    title: "是否有效",
    width: 90,
    dataIndex: "validStatus",
    ellipsis: true,
  },

  {
    title: "俱乐部模式",
    width: 100,
    dataIndex: "clubMode",
    ellipsis: true,
  },

  {
    title: "可创建的玩法",
    width: 200,
    dataIndex: "selGame",
    ellipsis: true,
  },

  {
    title: "是否可解散",
    width: 90,
    dataIndex: "isDismiss",
    ellipsis: true,
  },

  {
    title: "空桌显示",
    width: 100,
    dataIndex: "nullDeskType",
    ellipsis: true,
  },

  {
    title: "满桌显示",
    width: 150,
    dataIndex: "fullDeskType",
    ellipsis: true,
  },

  {
    title: "是否防暂离",
    width: 90,
    dataIndex: "isAfk",
    ellipsis: true,
  },

  {
    title: "防暂离时间(秒)",
    width: 110,
    dataIndex: "afkTime",
    ellipsis: true,
  },

  {
    title: "解散时间(秒)",
    width: 110,
    dataIndex: "dissmissTime",
    ellipsis: true,
  },

  {
    title: "是否可观战",
    width: 90,
    dataIndex: "isSpectators",
    ellipsis: true,
  },

  {
    title: "管理员数量",
    width: 90,
    dataIndex: "adminNum",
    ellipsis: true,
  },

  {
    title: "最小比例差值",
    width: 120,
    dataIndex: "smallDiff",
    ellipsis: true,
  },

  {
    title: "代理最大层级(不含会长)",
    width: 170,
    dataIndex: "maxTier",
    ellipsis: true,
  },

  {
    title: "公告",
    width: 170,
    dataIndex: "noticeStr",
    ellipsis: true,
  },

  {
    title: "创建时间",
    width: 180,
    dataIndex: "createTime",
    ellipsis: true,
  },

  {
    title: "操作",
    align: "center",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 110,
  },
];

export default defineComponent({
  components: {
    Edit,
    Info,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        clubId: undefined,
        clubName: "",
        creatorMaskId: undefined,
        creatorId: undefined,
        businessStatus: undefined,
        validStatus: undefined,
        isDismiss: undefined,
        selGame: [],
      },

      //点击搜索后的搜索数据
      relData: {
        selGame: [],
      },
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const editId = ref(0);

    const formRef = ref();

    const infoId = ref(0);
    const infoVisible = ref(false);

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
        selGame: search.relData.selGame.join(","),
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击编辑按钮
    const showEdit = (row: any) => {
      editId.value = row.clubId;
      visible.value = true;
    };

    //新增/修改成功
    const onEditSuccess = () => {
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //删除
    const del = (row: any) => {
      showConfirm({
        title: "删除确认",
        content: "确定删除该条记录吗?",
        onOk: async () => {
          await http.delete(`us_club/${row.id}`);
          getList();
          message.success("删除成功");
        },
      });
    };

    //显示详情
    const showInfo = (row: any) => {
      infoId.value = row.clubId;
      nextTick(() => {
        infoVisible.value = true;
      });
    };

    //修改状态
    // const changeClientState = async (row: any, checked: any) => {
    //   try {
    //     await http.put(`us_club/${row.id}/is_client_show`, { isClientShow: checked });
    //     message.success("操作成功");
    //   } catch (e) {
    //     row.states = checked == 1 ? 0 : 1;
    //   }
    // };

    //获取可创建的玩法
    const getGameTypeStr = (sel_game: string) => {
      //用逗号分割游戏类型
      const gameTypesArr = sel_game.split(",");
      const names = gameTypesArr.map((item: any) => {
        return getLabelByValue(gameTypes, item);
      });

      return names.join(",");
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      loading,
      handleTableChange,
      showEdit,
      editId,
      del,
      onSearch,
      onReset,
      onEditSuccess,
      searchCol,
      ClubBusinessMode,
      getLabelByValue,
      clubBusinessStatus,
      getGameTypeStr,
      gameTypes,
      infoId,
      infoVisible,
      showInfo,
      ClubBusinessStatusOn,
      ClubBusinessStatusOff,
      nullDeskTypes,
      fullDeskTypes,
    };
  },
});
