<template>
  <div>
    <l-form :rules="rules" :model="formState" ref="formRef">
      <a-spin :spinning="loading">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="选择状态" name="status">
              <a-radio-group name="status" v-model:value="formState.status">
                <a-radio value="1">启用</a-radio>
                <a-radio value="0">禁用</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-spin>

      <div class="l-drawer-edit-footer">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :disabled="loading" :loading="submitLoading">提交</a-button>
      </div>
    </l-form>
  </div>
</template>

<script lang="ts">
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const formRef = ref();
    const loading = ref(false);

    const submitLoading = ref(false);

    let formState = ref({
      status: "0",
    });

    //验证规则
    const rules = {
      status: [{ required: true, message: "请选择状态", trigger: "change" }],
    };

    //获取数据
    const getData = async () => {
      if (props.id) {
        //获取编辑数据
        loading.value = true;
        const { data } = await http.get(`us_club_rule/${props.id}`);
        formState.value = data.data;

        loading.value = false;
      }
    };

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    onMounted(() => {
      getData();
    });

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          //修改
          await http.put(`us_club_rule/${props.id}/status`, formState.value);
          message.success("修改成功");
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    return {
      loading,
      formRef,
      formState,
      submitLoading,
      rules,
      onClose,
      handleSubmit,
    };
  },
});
</script>
