<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '100px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部ID" name="clubId">
              <a-input v-model:value.number="search.data.clubId" placeholder="请输入俱乐部ID" allowClear> </a-input>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部名称" name="clubName">
              <a-input v-model:value="search.data.clubName" placeholder="请输入俱乐部名称" allowClear> </a-input>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="创建者游戏ID" name="creatorMaskId">
              <a-input v-model:value.number="search.data.creatorMaskId" placeholder="请输入创建者游戏ID" allowClear> </a-input>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="创建者userId" name="creatorId">
              <a-input v-model:value.number="search.data.creatorId" placeholder="请输入创建者userId" allowClear> </a-input>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="是否营业" name="businessStatus">
              <a-select v-model:value="search.data.businessStatus" placeholder="请选择是否营业" allowClear>
                <a-select-option :value="item.value" v-for="item in clubBusinessStatus" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="是否有效" name="validStatus">
              <a-select v-model:value="search.data.validStatus" placeholder="请选择是否有效" allowClear>
                <a-select-option :value="1">有效</a-select-option>
                <a-select-option :value="0">无效</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="是否可解散" name="isDismiss">
              <a-select v-model:value="search.data.isDismiss" placeholder="请选择是否可解散" allowClear>
                <a-select-option :value="1">可解散</a-select-option>
                <a-select-option :value="0">不可解散</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="searchCol">
            <a-form-item label="可创建玩法" name="selGame">
              <a-select v-model:value="search.data.selGame" mode="multiple" placeholder="请选择可创建玩法" allowClear>
                <a-select-option :value="item.value" v-for="item in gameTypes" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <a-spin :spinning="loading">
      <a-list :grid="{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3, xxxl: 3 }" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-card :title="item.clubId + ' -- ' + item.clubName" hoverable :body-style="{ padding: '5px 10px' }" :head-style="{ padding: '5px 10px' }">
              <a-descriptions title="" size="small" :label-style="{ fontWeight: 'bold' }">
                <a-descriptions-item label="创建人">{{ item.creatorMaskId }} -- {{ item.creatorName }} </a-descriptions-item>
                <a-descriptions-item label="当前用户数量">{{ item.currNumb }} </a-descriptions-item>
                <a-descriptions-item label="俱乐部人数">{{ item.currNumb }}/{{ item.maxLimitNumb }}</a-descriptions-item>
                <a-descriptions-item label="是否营业">
                  <a-tag color="success" v-show="item.businessStatus == ClubBusinessStatusOn">营业</a-tag>
                  <a-tag color="error" v-show="item.businessStatus == ClubBusinessStatusOff">打烊</a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="是否有效">
                  <a-tag color="error" v-show="item.validStatus == 0">无效</a-tag>
                  <a-tag color="success" v-show="item.validStatus == 1">有效</a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="俱乐部模式">{{ getLabelByValue(ClubBusinessMode, item.clubMode) }}</a-descriptions-item>
                <a-descriptions-item label="可创建的玩法">{{ getGameTypeStr(item.selGame) }}</a-descriptions-item>
                <a-descriptions-item label="是否可解散">
                  <a-tag color="error" v-show="item.isDismiss == 0">不可解散</a-tag>
                  <a-tag color="success" v-show="item.isDismiss == 1">可解散</a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="空桌显示"> {{ getLabelByValue(nullDeskTypes, item.nullDeskType) }}</a-descriptions-item>
                <a-descriptions-item label="满桌显示"> {{ getLabelByValue(fullDeskTypes, item.fullDeskType) }}</a-descriptions-item>
                <a-descriptions-item label="是否防暂离">
                  <a-tag color="success" v-show="item.isAfk === 1">开启</a-tag>
                  <a-tag color="error" v-show="item.isAfk === 0">关闭</a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="防暂离时间"> {{ item.afkTime }} 秒</a-descriptions-item>
                <a-descriptions-item label="解散时间"> {{ item.dissmissTime }} 秒</a-descriptions-item>
                <a-descriptions-item label="是否可观战">
                  <a-tag color="error" v-show="item.isSpectators == 0">不可观战</a-tag>
                  <a-tag color="success" v-show="item.isSpectators == 1">可观战</a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="管理员数量"> {{ item.adminNum }} </a-descriptions-item>
                <a-descriptions-item label="最小比例差值"> {{ item.smallDiff }} </a-descriptions-item>
                <a-descriptions-item label="代理最大层级(不含会长)"> {{ item.maxTier }} </a-descriptions-item>
                <a-descriptions-item label="公告"> {{ item.noticeStr }} </a-descriptions-item>
                <a-descriptions-item label="创建时间">{{ item.createTime }}</a-descriptions-item>
              </a-descriptions>

              <template #actions>
                <l-auth-text-button auth="us_club_update_btn" type="warning" primary @click="showEdit(item)">
                  <EditFilled />
                  更改设置
                </l-auth-text-button>
                <l-auth-text-button auth="us_club_info_btn" type="primary" primary @click="showInfo(item)">
                  <ExclamationCircleOutlined />
                  成员/规则
                </l-auth-text-button>
              </template>
            </a-card>
          </a-list-item>
        </template>
      </a-list>

      <div style="text-align: right">
        <a-pagination v-model:current="pagination.current" size="small" v-model:page-size="pagination.pageSize" :total="pagination.total" :show-total="pagination.showTotal" @change="handleTableChange" />
      </div>
    </a-spin>

    <!-- <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="clubId" :loading="loading">

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'creatorIcon'">
          <a-avatar :size="40" :src="record.creatorIcon"> </a-avatar>
        </template>

        <template v-if="column.dataIndex === 'businessStatus'">
          <a-tag color="success" v-show="record.businessStatus == ClubBusinessStatusOn">营业</a-tag>
          <a-tag color="error" v-show="record.businessStatus == ClubBusinessStatusOff">打烊</a-tag>
        </template>

        <template v-if="column.dataIndex === 'isAfk'">
          <a-tag color="success" v-show="record.isAfk === 1">开启</a-tag>
          <a-tag color="error" v-show="record.isAfk === 0">关闭</a-tag>
        </template>

        <template v-if="column.dataIndex === 'validStatus'">
          <a-tag color="error" v-show="record.validStatus == 0">无效</a-tag>
          <a-tag color="success" v-show="record.validStatus == 1">有效</a-tag>
        </template>

        <template v-if="column.dataIndex === 'isSpectators'">
          <a-tag color="error" v-show="record.isSpectators == 0">不可观战</a-tag>
          <a-tag color="success" v-show="record.isSpectators == 1">可观战</a-tag>
        </template>

        <template v-if="column.dataIndex === 'clubMode'">
          {{ getLabelByValue(ClubBusinessMode, record.clubMode) }}
        </template>

        <template v-if="column.dataIndex === 'nullDeskType'">
          {{ getLabelByValue(nullDeskTypes, record.nullDeskType) }}
        </template>

        <template v-if="column.dataIndex === 'fullDeskType'">
          {{ getLabelByValue(fullDeskTypes, record.fullDeskType) }}
        </template>

        <template v-if="column.dataIndex === 'selGame'">
          {{ getGameTypeStr(record.selGame) }}
        </template>

        <template v-if="column.dataIndex === 'isDismiss'">
          <a-tag color="error" v-show="record.isDismiss == 0">不可解散</a-tag>
          <a-tag color="success" v-show="record.isDismiss == 1">可解散</a-tag>
        </template>

        <template v-if="column.dataIndex === 'action'">
          <l-auth-text-button auth="us_club_update_btn" type="warning" primary @click="showEdit(record)">
            <EditFilled />
            更改设置
          </l-auth-text-button>
          <l-auth-text-button auth="us_club_info_btn" type="primary" primary @click="showInfo(record)">
            <ExclamationCircleOutlined />
            成员/规则
          </l-auth-text-button>
        </template>
      </template>
    </l-table> -->

    <l-drawer :title="editId == 0 ? '新增' : '编辑'" :width="800" v-model:visible="visible" :body-style="{ paddingBottom: '80px' }">
      <Edit ref="edit_ref" @close="visible = false" @success="onEditSuccess" v-model:id="editId" />
    </l-drawer>

    <l-drawer title="" v-model:visible="infoVisible" width="95%" maskClosable mask :body-style="{ paddingBottom: '80px' }" class="l-drawer-grey-bg">
      <Info @close="infoVisible = false" :club-id="infoId" />
    </l-drawer>
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { getLabelByValue, ClubBusinessMode, clubBusinessStatus, gameTypes, ClubBusinessStatusOn, ClubBusinessStatusOff, nullDeskTypes, fullDeskTypes } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import Edit from "./Edit.vue";
import Info from "./Info.vue";
import { message, PaginationProps } from "ant-design-vue";
import { showConfirm } from "@/common/utils";

const columns = [
  // {
  //   title: "id",
  //   width: 90,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "俱乐部ID",
    width: 100,
    dataIndex: "clubId",
    ellipsis: true,
    fixed: "left",
  },

  {
    title: "俱乐部名称",
    width: 110,
    dataIndex: "clubName",
    ellipsis: true,
  },

  {
    title: "创建者userId",
    width: 110,
    dataIndex: "creatorId",
    ellipsis: true,
  },

  {
    title: "创建者游戏ID",
    width: 110,
    dataIndex: "creatorMaskId",
    ellipsis: true,
  },

  {
    title: "创建者昵称",
    width: 110,
    dataIndex: "creatorName",
    ellipsis: true,
  },

  {
    title: "创建者头像",
    width: 90,
    dataIndex: "creatorIcon",
    ellipsis: true,
  },

  {
    title: "当前用户数量",
    width: 110,
    dataIndex: "currNumb",
    ellipsis: true,
  },

  {
    title: "最大人数限制",
    width: 110,
    dataIndex: "maxLimitNumb",
    ellipsis: true,
  },

  {
    title: "是否营业",
    width: 90,
    dataIndex: "businessStatus",
    ellipsis: true,
  },

  {
    title: "是否有效",
    width: 90,
    dataIndex: "validStatus",
    ellipsis: true,
  },

  {
    title: "俱乐部模式",
    width: 100,
    dataIndex: "clubMode",
    ellipsis: true,
  },

  {
    title: "可创建的玩法",
    width: 200,
    dataIndex: "selGame",
    ellipsis: true,
  },

  {
    title: "是否可解散",
    width: 90,
    dataIndex: "isDismiss",
    ellipsis: true,
  },

  {
    title: "空桌显示",
    width: 100,
    dataIndex: "nullDeskType",
    ellipsis: true,
  },

  {
    title: "满桌显示",
    width: 150,
    dataIndex: "fullDeskType",
    ellipsis: true,
  },

  {
    title: "是否防暂离",
    width: 90,
    dataIndex: "isAfk",
    ellipsis: true,
  },

  {
    title: "防暂离时间(秒)",
    width: 110,
    dataIndex: "afkTime",
    ellipsis: true,
  },

  {
    title: "解散时间(秒)",
    width: 110,
    dataIndex: "dissmissTime",
    ellipsis: true,
  },

  {
    title: "是否可观战",
    width: 90,
    dataIndex: "isSpectators",
    ellipsis: true,
  },

  {
    title: "管理员数量",
    width: 90,
    dataIndex: "adminNum",
    ellipsis: true,
  },

  {
    title: "最小比例差值",
    width: 120,
    dataIndex: "smallDiff",
    ellipsis: true,
  },

  {
    title: "代理最大层级(不含会长)",
    width: 170,
    dataIndex: "maxTier",
    ellipsis: true,
  },

  {
    title: "公告",
    width: 170,
    dataIndex: "noticeStr",
    ellipsis: true,
  },

  {
    title: "创建时间",
    width: 180,
    dataIndex: "createTime",
    ellipsis: true,
  },

  {
    title: "操作",
    align: "center",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 110,
  },
];

export default defineComponent({
  components: {
    Edit,
    Info,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        clubId: undefined,
        clubName: "",
        creatorMaskId: undefined,
        creatorId: undefined,
        businessStatus: undefined,
        validStatus: undefined,
        isDismiss: undefined,
        selGame: [],
      },

      //点击搜索后的搜索数据
      relData: {
        selGame: [],
      },
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const editId = ref(0);

    const formRef = ref();

    const infoId = ref(0);
    const infoVisible = ref(false);

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
        selGame: search.relData.selGame.join(","),
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击编辑按钮
    const showEdit = (row: any) => {
      editId.value = row.clubId;
      visible.value = true;
    };

    //新增/修改成功
    const onEditSuccess = () => {
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //删除
    const del = (row: any) => {
      showConfirm({
        title: "删除确认",
        content: "确定删除该条记录吗?",
        onOk: async () => {
          await http.delete(`us_club/${row.id}`);
          getList();
          message.success("删除成功");
        },
      });
    };

    //显示详情
    const showInfo = (row: any) => {
      infoId.value = row.clubId;
      nextTick(() => {
        infoVisible.value = true;
      });
    };

    //修改状态
    // const changeClientState = async (row: any, checked: any) => {
    //   try {
    //     await http.put(`us_club/${row.id}/is_client_show`, { isClientShow: checked });
    //     message.success("操作成功");
    //   } catch (e) {
    //     row.states = checked == 1 ? 0 : 1;
    //   }
    // };

    //获取可创建的玩法
    const getGameTypeStr = (sel_game: string) => {
      //用逗号分割游戏类型
      const gameTypesArr = sel_game.split(",");
      const names = gameTypesArr.map((item: any) => {
        return getLabelByValue(gameTypes, item);
      });

      return names.join(",");
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      loading,
      handleTableChange,
      showEdit,
      editId,
      del,
      onSearch,
      onReset,
      onEditSuccess,
      searchCol,
      ClubBusinessMode,
      getLabelByValue,
      clubBusinessStatus,
      getGameTypeStr,
      gameTypes,
      infoId,
      infoVisible,
      showInfo,
      ClubBusinessStatusOn,
      ClubBusinessStatusOff,
      nullDeskTypes,
      fullDeskTypes,
    };
  },
});
</script>
