
import { mallGoodsTypes, mallBearnsImgList, mallGoldImgList, BeanMallType, GoldMallType, ClubBusinessStatusOff, ClubBusinessStatusOn, gameTypes, ClubBusinessMode } from "@/common/head";
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    id: {
      type: Number,
    },
  },
  components: {},
  setup(props, { emit }) {
    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();

    const submitLoading = ref(false);

    const formState = ref({
      clubName: "",
      maxLimitNumb: undefined,
      businessStatus: 1,
      validStatus: 1,
      selGame: [],
      isDismiss: 1,
      dissmissTime: 60,
      maxTier: 5,
      isAfk: 0,
      afkTime: 0,
      isSpectators: 0,
      smallDiff: 0,
      clubMode: 1,
      noticeStr: "",
    });

    //验证规则
    const rules = {
      clubName: [{ required: true, message: "请输入俱乐部名称", trigger: "change" }],
      maxLimitNumb: [{ required: true, message: "请输入最大人数限制", trigger: "change", type: "number" }],
      businessStatus: [{ required: true, message: "请选择是否营业", trigger: "change", type: "number" }],
      validStatus: [{ required: true, message: "请选择是否有效", trigger: "change", type: "number" }],
      selGame: [{ required: true, message: "请选择玩法", trigger: "change", type: "array" }],
      isDismiss: [{ required: true, message: "请选择是否可解散", trigger: "change", type: "number" }],
      dissmissTime: [{ required: true, message: "请输入解散时间", trigger: "change", type: "number" }],
      maxTier: [{ required: true, message: "请输入代理最大层级", trigger: "change", type: "number" }],
      isAfk: [{ required: true, message: "请选择是否防暂离", trigger: "change", type: "number" }],
      afkTime: [{ required: true, message: "请输入防暂离时间", trigger: "change", type: "number" }],
      isSpectators: [{ required: true, message: "请选择是否允许观战", trigger: "change", type: "number" }],
      smallDiff: [{ required: true, message: "请输入最小比例差值", trigger: "change", type: "number" }],
      clubMode: [{ required: true, message: "请选择俱乐部模式", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //获取数据
    const getData = async () => {
      loading.value = true;
      if (props.id) {
        //获取编辑数据
        const { data } = await http.get(`us_club/${props.id}`);
        data.formData.selGame = data.formData.selGame.split(",").map((item: any) => {
          return parseInt(item);
        });
        formState.value = data.formData;
      }

      loading.value = false;
    };

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          if (!props.id) {
            console.log(props.id);

            // //新增
            // await http.post("us_club", formState.value);
            // message.success("新增成功");
          } else {
            //修改
            await http.put(`us_club/${props.id}`, {
              ...formState.value,
              selGame: formState.value.selGame.join(","),
            });
            message.success("修改成功");
          }
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    onMounted(() => {
      getData();
    });

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      mallBearnsImgList,
      mallGoldImgList,
      mallGoodsTypes,
      BeanMallType,
      GoldMallType,
      ClubBusinessStatusOff,
      ClubBusinessStatusOn,
      gameTypes,
      ClubBusinessMode,
      onClose,
      handleSubmit,
    };
  },
});
