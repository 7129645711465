
import { DeskFanList, getLabelByValue, roomChargeTypes, autoCountDissTypes } from "@/common/head";
import { getValue } from "@/common/utils";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    ruleStr: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const ruleData = JSON.parse(props.ruleStr);

    return {
      ruleData,
      DeskFanList,
      roomChargeTypes,
      autoCountDissTypes,
      getValue,
      getLabelByValue,
    };
  },
});
