
import http from "@/common/http";
import { ClubMemberIdentityPresident, clubMemberIdentitys, gameTypes, getLabelByValue, sexTypes } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import ChangeRuleState from "./ChangeRuleState.vue";
import RuleInfo from "./RuleInfo.vue";
import UpdPercent from "./UpdPercent.vue";

import { showConfirm } from "@/common/utils";
import { message } from "ant-design-vue";
// import { showConfirm } from "@/common/utils";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ChangeRuleState,
    UpdPercent,
    RuleInfo,
  },
  setup(props) {
    const columns = ref([
      {
        title: "规则名称",
        width: 140,
        dataIndex: "ruleName",

        // fixed: "left",
      },

      {
        title: "规则内容",
        width: 140,
        name: "ruleStr",
        key: "ruleStr",
        dataIndex: "ruleStr",
      },

      {
        title: "状态",
        width: 90,
        dataIndex: "status",
        ellipsis: true,
      },

      {
        title: "游戏类型",
        width: 90,
        dataIndex: "gameType",
        ellipsis: true,
      },

      {
        title: "服务类型",
        width: 90,
        dataIndex: "servType",
        ellipsis: true,
      },

      {
        title: "创建人userId",
        width: 120,
        dataIndex: "createId",
        ellipsis: true,
      },

      {
        title: "创建人游戏ID",
        width: 120,
        dataIndex: "createMaskId",
        ellipsis: true,
      },

      {
        title: "创建人昵称",
        width: 120,
        dataIndex: "createName",
        ellipsis: true,
      },

      {
        title: "创建时间",
        width: 180,
        dataIndex: "createTime",
        ellipsis: true,
      },

      {
        title: "更新时间",
        width: 180,
        dataIndex: "updTime",
        ellipsis: true,
      },

      {
        title: "备注",
        width: 180,
        dataIndex: "remark",
        ellipsis: true,
      },

      {
        title: "操作",
        align: "center",
        key: "action",
        dataIndex: "action",
        // fixed: "right",
        width: 200,
      },
    ]);

    //搜索成员数据
    const memberSearch = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        memberMaskId: undefined,
        memberId: undefined,
      },

      //点击搜索后的搜索数据
      relData: {
        memberMaskId: undefined,
        memberId: undefined,
      },
    });

    //比例表列信息
    const percentColumns = ref([
      {
        title: "成员",
        width: 400,
        dataIndex: "memberMaskId",
        ellipsis: true,
        // fixed: "left",
      },

      {
        title: "分配比例",
        width: 100,
        dataIndex: "percent",
      },

      // {
      //   title: "成员userId",
      //   width: 100,
      //   dataIndex: "memberId",
      // },

      // {
      //   title: "成员身份",
      //   width: 80,
      //   dataIndex: "memberIdentity",
      // },

      // {
      //   title: "成员等级",
      //   width: 80,
      //   dataIndex: "memberLevel",
      // },

      // // {
      // //   title: "头像",
      // //   width: 80,
      // //   dataIndex: "memberIcon",
      // //   slots: { customRender: "memberIcon" },
      // //   ellipsis: true,
      // // },

      // {
      //   title: "昵称",
      //   width: 120,
      //   dataIndex: "memberName",
      //   ellipsis: true,
      // },

      {
        title: "操作",
        align: "center",
        key: "action",
        dataIndex: "action",
        // fixed: "right",
        width: 120,
      },
    ]);

    const percentData = reactive<any>({
      data: [], //代理比例数据
      loading: false, //是否加载中
      expandedRowKeys: [], //展开的代理数据
      ruleId: undefined, //当前选中的规则ID
      ruleName: "", //当前选中的规则名称
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const formRef = ref();

    const updStateVisible = ref(false);
    const editId = ref(0);

    const currRuleStr = ref("");
    const ruleVisible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get(`us_club/${props.clubId}/rule`, {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...memberSearch.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //获取比例列表
    const getPercentList = async (record: any) => {
      percentData.ruleId = record.id;
      percentData.ruleName = record.ruleName;
      percentData.loading = true;
      const result = await http.get(`us_club_rule/${record.id}/us_club_rule_allocation`, {
        ...memberSearch.relData,
      });

      percentData.data = result.data.treeData;
      percentData.expandedRowKeys = result.data.expendIds;

      percentData.loading = false;
    };

    //点击更改状态按钮
    const showUpdState = (row: any) => {
      editId.value = row.id;
      updStateVisible.value = true;
    };

    //点击搜索按钮
    const onSearch = () => {
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      onSearch();
    };

    //点击分配比例搜索按钮
    const onMemberSearch = () => {
      memberSearch.relData = memberSearch.data;
      onUpdPercentSuccess();
    };

    //点击成员搜索重置按钮
    const onMemberReset = () => {
      formRef.value.resetFields();
      memberSearch.relData = {
        memberMaskId: undefined,
        memberId: undefined,
      };
      onUpdPercentSuccess();
    };

    //新增/修改成功
    const onUpdSuccess = () => {
      editId.value = 0;
      getList();
    };

    //监听展开事件
    const handleExpanded = (expend: boolean, record: any) => {
      if (expend) {
        percentData.expandedRowKeys.push(record.memberId);
      } else {
        const index = percentData.expandedRowKeys.findIndex((item: any) => {
          return item == record.memberId;
        });
        percentData.expandedRowKeys.splice(index, 1);
      }
    };

    //显示修改比例弹窗
    const updPercentVisible = ref(false);
    const updPercentMemberId = ref(0);
    const showUpdPercent = (row: any) => {
      updPercentMemberId.value = row.memberId;
      nextTick(() => {
        updPercentVisible.value = true;
      });
    };

    //修改比例成功
    const onUpdPercentSuccess = () => {
      getPercentList({
        id: percentData.ruleId,
        ruleName: percentData.ruleName,
      });
    };

    //搜索

    onMounted(async () => {
      await getList();

      if (data.value.length > 0) {
        await getPercentList(data.value[0]);
      }
    });

    //清除玩法
    const clearRule = (row: any) => {
      showConfirm({
        title: "清除确认",
        content: `注意，本操作将清除##${row.memberMaskId}##及其所有下级代理的《${percentData.ruleName}》玩法（即将本玩法的比例设置为0），确定要清除吗？`,
        onOk: async () => {
          await http.delete(`us_club_rule/${percentData.ruleId}/member/${row.memberId}/us_club_rule_allocation/percent`);
          onUpdPercentSuccess();
          message.success("清除成功");
        },
      });
    };

    //点击显示规则内容
    const showRuleStr = (row: any) => {
      currRuleStr.value = row.ruleStr;
      ruleVisible.value = true;
    };

    return {
      data,
      columns,
      memberSearch,
      pagination,
      edit_ref,
      formRef,
      editId,
      loading,
      updStateVisible,
      onSearch,
      onReset,
      searchCol,
      sexTypes,
      getLabelByValue,
      onUpdSuccess,
      showUpdState,
      gameTypes,
      percentColumns,
      percentData,
      handleExpanded,
      clubMemberIdentitys,
      getPercentList,
      showUpdPercent,
      updPercentVisible,
      updPercentMemberId,
      ClubMemberIdentityPresident,
      onUpdPercentSuccess,
      clearRule,
      onMemberSearch,
      onMemberReset,
      showRuleStr,
      currRuleStr,
      ruleVisible,
    };
  },
});
