<template>
  <div>
    <l-form :rules="rules" :model="formState" ref="formRef">
      <a-spin :spinning="loading">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="成员信息">
              <div>
                <span class="l-font-num">{{ memberMaskId }}</span> -- {{ memberData.memberName }}
              </div>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="成员身份" name="memberIdentity">
              <a-row :gutter="20" align="middle">
                <a-col>
                  <div style="margin-left: 10px" class="l-font-num">{{ getLabelByValue(clubMemberSelAbleIdentitys, memberData.memberIdentity) }}</div>
                </a-col>
                <a-col> 转换成=> </a-col>
                <a-col :flex="1">
                  <a-select v-model:value="formState.memberIdentity" placeholder="请选择成员身份">
                    <a-select-option :value="item.value" v-for="item in identityList" :key="item.value">{{ item.label }}</a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
        </a-row>
      </a-spin>

      <a-alert type="error">
        <template #message> 注意：由代理转换成成员，该代理整条线下的成员和代理，<span class="l-font-danger l-bold">将会转移到其上级代理名下</span> </template>
      </a-alert>

      <div class="l-drawer-edit-footer">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :disabled="loading" :loading="submitLoading">提交</a-button>
      </div>
    </l-form>
  </div>
</template>

<script lang="ts">
import { clubMemberSelAbleIdentitys, paltformTypes, sexTypes, getLabelByValue, ClubMemberIdentityAgent, ClubMemberIdentityMember } from "@/common/head";
import http from "@/common/http";
import { message } from "ant-design-vue";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
    memberMaskId: {
      type: Number,
      required: true,
    },
  },
  components: {},
  setup(props, { emit }) {
    const memberData = ref({
      memberName: "",
      memberIdentity: -1,
    });

    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();

    const submitLoading = ref(false);

    const fileList = ref<any[]>([]);
    const formState = ref<any>({
      memberMaskId: props.memberMaskId,
      memberIdentity: undefined,
    });

    //验证规则
    const rules = {
      maskId: [{ required: true, message: "请输入玩家游戏ID", trigger: "change", type: "number" }],
      memberIdentity: [{ required: true, message: "请选择身份", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("close");
    };

    //获取更改身份的信息
    const getMemberData = async () => {
      const { data } = await http.get(`us_club/${props.clubId}/member/${props.memberMaskId}/upd_identity`, formState.value);
      memberData.value = data.memberData;
    };

    //可选的成员身份
    const identityList = computed(() => {
      return clubMemberSelAbleIdentitys.filter((item) => {
        //去掉当前的身份
        if (item.value == memberData.value.memberIdentity) {
          return false;
        }

        //如果是代理，只能变更成成员
        if (memberData.value.memberIdentity == ClubMemberIdentityAgent && item.value != ClubMemberIdentityMember) {
          return false;
        }

        return true;
      });
    });

    onMounted(() => {
      getMemberData();
    });

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;

        try {
          //保存
          await http.put(`us_club/${props.clubId}/member/${props.memberMaskId}/upd_identity`, formState.value);
          message.success("修改成功");
          submitLoading.value = false;
          onClose();
          emit("success");
        } catch (e) {
          submitLoading.value = false;
        }
      });
    };

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      paltformTypes,
      fileList,
      sexTypes,
      memberData,
      identityList,
      clubMemberSelAbleIdentitys,
      onClose,
      handleSubmit,
      getMemberData,
      getLabelByValue,
    };
  },
});
</script>
