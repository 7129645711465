<template>
  <div>
    <l-form :rules="rules" :model="formState" ref="formRef">
      <a-spin :spinning="loading">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="所属俱乐部">
              <div>
                <span class="l-font-num">{{ clubData.clubId }}</span> -- {{ clubData.clubName }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="上级代理">
              <div>
                <span class="l-font-num">{{ upMemberMaskId }}</span> -- {{ upMemberData.nick_name }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="要绑定的玩家游戏ID" name="maskId">
              <a-input v-model:value.number="formState.maskId" placeholder="请输入玩家游戏ID" autocomplete="off" />
              <div style="margin-top: 20px" v-if="formState.maskId">
                <template v-if="memberData.nick_name">
                  <div style="display: inline-block">
                    昵称：<span class="l-font-danger">{{ memberData.nick_name }}</span>
                  </div>
                </template>

                <template v-else><span class="l-font-danger">玩家信息不存在</span></template>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="成员身份" name="memberIdentity">
              <a-select v-model:value="formState.memberIdentity" placeholder="请选择成员身份">
                <a-select-option :value="item.value" v-for="item in clubMemberSelAbleIdentitys" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-spin>

      <div class="l-drawer-edit-footer">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :disabled="loading" :loading="submitLoading">提交</a-button>
      </div>
    </l-form>
  </div>
</template>

<script lang="ts">
import { clubMemberSelAbleIdentitys, paltformTypes, sexTypes } from "@/common/head";
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
    upMemberMaskId: {
      type: Number,
      required: true,
    },
  },
  components: {},
  setup(props, { emit }) {
    const clubData = ref({
      clubName: "",
      clubId: "",
    });

    const upMemberData = ref({
      nick_name: "",
    });

    const memberData = ref({
      nick_name: "",
    });

    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();

    const submitLoading = ref(false);

    const fileList = ref<any[]>([]);
    const formState = ref<any>({
      upMemberMaskId: props.upMemberMaskId,
      maskId: undefined,
      memberIdentity: undefined,
    });

    //验证规则
    const rules = {
      maskId: [{ required: true, message: "请输入玩家游戏ID", trigger: "change", type: "number" }],
      memberIdentity: [{ required: true, message: "请选择身份", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("close");
    };

    //获取俱乐部信息
    const getClubData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_club/${props.clubId}`, formState.value);
      clubData.value = data.formData;
      loading.value = false;
    };

    //获取上级信息
    const getUpMemberData = async () => {
      loading.value = true;
      const { data } = await http.get(`us_user/mask_id/${props.upMemberMaskId}`, formState.value);
      upMemberData.value = data.data;
      loading.value = false;
    };

    //获取成员信息
    const getMemberData = async () => {
      if (formState.value.maskId && formState.value.maskId > 10000) {
        const { data } = await http.get(`us_user/mask_id/${formState.value.maskId}`, formState.value);
        memberData.value = data.data;
      }
    };

    watch(
      () => {
        return formState.value.maskId;
      },
      () => {
        getMemberData();
      }
    );

    onMounted(() => {
      getClubData();
      getUpMemberData();
    });

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;

        try {
          //保存
          await http.post(`us_club/${props.clubId}/member`, formState.value);
          message.success("保存成功");
          submitLoading.value = false;
          onClose();
          emit("success");
        } catch (e) {
          submitLoading.value = false;
        }
      });
    };

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      paltformTypes,
      fileList,
      sexTypes,
      clubData,
      upMemberData,
      memberData,
      onClose,
      handleSubmit,
      getMemberData,
      clubMemberSelAbleIdentitys,
    };
  },
});
</script>
