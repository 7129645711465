<template>
  <div>
    <l-form :rules="rules" :model="formState" ref="formRef">
      <a-spin :spinning="loading">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-item label="比例" name="percent"> <a-input-number v-model:value="formState.percent" :min="0" :max="100" :step="1" /> % </a-form-item>
          </a-col>
        </a-row>
      </a-spin>

      <div class="l-drawer-edit-footer">
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleSubmit" :disabled="loading" :loading="submitLoading">提交</a-button>
      </div>
    </l-form>
  </div>
</template>

<script lang="ts">
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    memberId: {
      type: Number,
      required: true,
    },
    ruleId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref();
    const loading = ref(false);

    const submitLoading = ref(false);

    let formState = ref({
      percent: 0,
    });

    //验证规则
    const rules = {
      percent: [{ required: true, message: "请输入分成比例", trigger: "change", type: "number" }],
    };

    //获取数据
    const getData = async () => {
      //获取编辑数据
      loading.value = true;
      const { data } = await http.get(`us_club_rule/${props.ruleId}/member/${props.memberId}/us_club_rule_allocation`);
      formState.value = data.data;

      loading.value = false;
    };

    //点击取消
    const onClose = () => {
      emit("close");
    };

    onMounted(() => {
      getData();
    });

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          //修改
          await http.put(`us_club_rule/${props.ruleId}/member/${props.memberId}/us_club_rule_allocation/percent`, formState.value);
          message.success("修改成功");
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    return {
      loading,
      formRef,
      formState,
      submitLoading,
      rules,
      onClose,
      handleSubmit,
    };
  },
});
</script>
