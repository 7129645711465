<template>
  <div>
    <a-spin :spinning="loading">
      <a-tabs v-model:activeKey="activeKey" size="large" :tabBarStyle="{ background: '#fff', paddingLeft: '15px', marginBottom: '5px' }">
        <a-tab-pane key="1" tab="层级关系" force-render>
          <div style="margin: 0 3px">
            <AgentTree :club-id="clubId"></AgentTree>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="玩法规则/分配比例">
          <div style="margin: 0 3px">
            <RuleList :club-id="clubId"></RuleList>
          </div>
        </a-tab-pane>
        <!-- <a-tab-pane key="3" tab="成员列表">
          <div style="margin: 0 15px">
            <MemberList :club-id="clubId"></MemberList>
          </div>
        </a-tab-pane> -->
      </a-tabs>
    </a-spin>
    <div class="l-drawer-edit-footer">
      <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
    </div>
  </div>
</template>

<script lang="ts">
import { getLabelByValue, roomChargeTypes, gameTypes, gamePlayerTypes, DeskFanList, actTypes, hnmjCardImgList, getIconByValue, ActTypeFlower } from "@/common/head";
import { defineComponent, onMounted, ref } from "vue";
// import MemberList from "./MemberList.vue";
import AgentTree from "./AgentTree.vue";
import RuleList from "./RuleList.vue";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
  },
  components: {
    // MemberList,
    AgentTree,
    RuleList,
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const dataSource = ref({});
    const activeKey = ref("1");

    const createMaskId = ref(0);

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //获取数据
    // const getData = async () => {
    //   loading.value = true;
    //   const { data } = await http.get(`us_club/${props.clubId}`);

    //   console.log(data);

    //   loading.value = false;
    // };

    onMounted(() => {
      // getData();
    });

    return {
      loading,
      onClose,
      dataSource,
      gameTypes,
      roomChargeTypes,
      getLabelByValue,
      gamePlayerTypes,
      DeskFanList,
      createMaskId,
      actTypes,
      hnmjCardImgList,
      ActTypeFlower,
      getIconByValue,
      activeKey,
    };
  },
});
</script>
