
import { getLabelByValue, roomChargeTypes, gameTypes, gamePlayerTypes, DeskFanList, actTypes, hnmjCardImgList, getIconByValue, ActTypeFlower } from "@/common/head";
import { defineComponent, onMounted, ref } from "vue";
// import MemberList from "./MemberList.vue";
import AgentTree from "./AgentTree.vue";
import RuleList from "./RuleList.vue";

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
  },
  components: {
    // MemberList,
    AgentTree,
    RuleList,
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const dataSource = ref({});
    const activeKey = ref("1");

    const createMaskId = ref(0);

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //获取数据
    // const getData = async () => {
    //   loading.value = true;
    //   const { data } = await http.get(`us_club/${props.clubId}`);

    //   console.log(data);

    //   loading.value = false;
    // };

    onMounted(() => {
      // getData();
    });

    return {
      loading,
      onClose,
      dataSource,
      gameTypes,
      roomChargeTypes,
      getLabelByValue,
      gamePlayerTypes,
      DeskFanList,
      createMaskId,
      actTypes,
      hnmjCardImgList,
      ActTypeFlower,
      getIconByValue,
      activeKey,
    };
  },
});
