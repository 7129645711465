<template>
  <div style="padding: 20px">
    <!-- <a-descriptions title="规则代码"> </a-descriptions>
    <json-viewer :value="ruleData" boxed sort /> -->

    <a-descriptions title="规则信息" style="margin-top: 10px"> </a-descriptions>
    <a-descriptions title="" bordered>
      <a-descriptions-item label="一场开的总局数">{{ ruleData.roundCount }}</a-descriptions-item>
      <a-descriptions-item label="玩家数量">{{ ruleData.playerType }}</a-descriptions-item>
      <a-descriptions-item label="付费类型">{{ getLabelByValue(roomChargeTypes, ruleData.isFan) }}</a-descriptions-item>
      <a-descriptions-item label="底分">{{ ruleData.basePoint }}</a-descriptions-item>
      <a-descriptions-item label="有番/无番">{{ getLabelByValue(DeskFanList, ruleData.isFan) }}</a-descriptions-item>
      <a-descriptions-item label="封顶倍率">{{ ruleData.cappingRate }}</a-descriptions-item>
      <a-descriptions-item label="是否庄闲">
        <a-tag color="success" v-show="ruleData.isDealer === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isDealer === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否连庄">
        <a-tag color="success" v-show="ruleData.isRTD === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isRTD === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否上噶">
        <a-tag color="success" v-show="ruleData.isUpG === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isUpG === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="上多少噶">
        {{ ruleData.setNumG }}
        <!-- <a-tag color="success" v-show="ruleData.setNumG">是</a-tag>
        <a-tag color="error" v-show="!ruleData.setNumG">否</a-tag> -->
      </a-descriptions-item>
      <a-descriptions-item label="是否流局/黄庄算分">
        <a-tag color="success" v-show="ruleData.isDraw === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isDraw === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否花胡">
        <a-tag color="success" v-show="ruleData.isFlower === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isFlower === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否花胡翻倍">
        <a-tag color="success" v-show="ruleData.isFlowerDoub === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isFlowerDoub === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否混一色翻倍">
        <a-tag color="success" v-show="ruleData.isChaosColorDoub === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isChaosColorDoub === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否无字牌">
        <a-tag color="success" v-show="ruleData.isNotDAW === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isNotDAW === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否叫令">
        <a-tag color="success" v-show="ruleData.isToken === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isToken === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否防勾脚">
        <a-tag color="success" v-show="ruleData.isHook === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isHook === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否海底包牌">
        <a-tag color="success" v-show="ruleData.isSeabed === true">是</a-tag>
        <a-tag color="error" v-show="!ruleData.isSeabed === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否三道/四道包牌">
        <a-tag color="success" v-show="ruleData.isThreeFour === true">是</a-tag>
        <a-tag color="error" v-show="!ruleData.isThreeFour === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="碰后杠翻倍">
        <a-tag color="success" v-show="ruleData.isPengExposed === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isPengExposed === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否随机庄家">
        <a-tag color="success" v-show="ruleData.isRandomDealer === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isRandomDealer === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否随机换位">
        <a-tag color="success" v-show="ruleData.isRandomPosIndex === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isRandomPosIndex === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否自动准备">
        <a-tag color="success" v-show="ruleData.isRead === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isRead === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="解散需要所有人同意">
        <a-tag color="success" v-show="ruleData.isDissAll === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isDissAll === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否不可吃牌">
        <a-tag color="success" v-show="ruleData.isNotEat === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isNotEat === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="是否不可点炮">
        <a-tag color="success" v-show="ruleData.isNotPao === true">是</a-tag>
        <a-tag color="error" v-show="ruleData.isNotPao === false">否</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="托管时间">
        <span v-if="ruleData.isAuto">{{ ruleData.isAuto }} 秒</span>
      </a-descriptions-item>
      <a-descriptions-item label="托管解散">
        {{ getLabelByValue(autoCountDissTypes, ruleData.isAuthCount) }}
      </a-descriptions-item>
      <a-descriptions-item label="托管局数">
        {{ ruleData.isAuthCount }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script lang="ts">
import { DeskFanList, getLabelByValue, roomChargeTypes, autoCountDissTypes } from "@/common/head";
import { getValue } from "@/common/utils";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    ruleStr: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const ruleData = JSON.parse(props.ruleStr);

    return {
      ruleData,
      DeskFanList,
      roomChargeTypes,
      autoCountDissTypes,
      getValue,
      getLabelByValue,
    };
  },
});
</script>
