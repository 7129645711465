
import http from "@/common/http";
import { ClubMemberIdentityMember, ClubMemberIdentityPresident, clubMemberIdentitys, getLabelByValue } from "@/common/head";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { searchCol } from "@/config/app";
import { message } from "ant-design-vue";
import { showConfirm } from "@/common/utils";
import BindMember from "./BindMember.vue";
import BindChange from "./BindChange.vue";
import UpdIdentity from "./UpdIdentity.vue";

const columns = [
  {
    title: "成员",
    width: 400,
    dataIndex: "memberMaskId",
    ellipsis: true,
    // fixed: "left",
  },
  // {
  //   title: "成员userId",
  //   width: 100,
  //   dataIndex: "memberId",
  // },

  // {
  //   title: "成员身份",
  //   width: 80,
  //   dataIndex: "memberIdentity",
  // },

  // {
  //   title: "成员等级",
  //   width: 80,
  //   dataIndex: "memberLevel",
  // },

  // {
  //   title: "头像",
  //   width: 80,
  //   dataIndex: "memberIcon",
  //   ellipsis: true,
  // },

  // {
  //   title: "昵称",
  //   width: 120,
  //   dataIndex: "memberName",
  //   ellipsis: true,
  // },

  {
    title: "初始体力值",
    width: 100,
    dataIndex: "initHp",
    ellipsis: true,
  },

  {
    title: "当前体力值",
    width: 100,
    dataIndex: "hpCoins",
    ellipsis: true,
  },

  // {
  //   title: "是否有效",
  //   width: 80,
  //   dataIndex: "isValid",
  //   ellipsis: true,
  // },

  {
    title: "是否在线",
    width: 80,
    dataIndex: "isOnline",
    ellipsis: true,
  },

  {
    title: "是否流失",
    width: 80,
    dataIndex: "isDisconn",
    ellipsis: true,
  },

  // {
  //   title: "上级成员userID",
  //   width: 120,
  //   dataIndex: "upMemberId",
  // },

  // {
  //   title: "上级成员游戏ID",
  //   width: 120,
  //   dataIndex: "upMemberMaskId",
  // },

  // {
  //   title: "上级成员昵称",
  //   width: 100,
  //   dataIndex: "upMemberName",
  // },

  {
    title: "加入时间",
    width: 180,
    dataIndex: "joinTime",
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    dataIndex: "action",
    fixed: "right",
    width: 70,
  },
];

export default defineComponent({
  props: {
    clubId: {
      type: Number,
      required: true,
    },
  },
  components: {
    BindMember,
    BindChange,
    UpdIdentity,
  },
  setup(props) {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        showType: 1,
        memberMaskId: undefined,
        memberId: undefined,
        memberIdentity: undefined,
        isDisconn: undefined,
      },

      //点击搜索后的搜索数据
      relData: {
        showType: 1,
        memberMaskId: undefined,
        memberId: undefined,
        memberIdentity: undefined,
        isDisconn: undefined,
      },
    });

    //表格加载中
    const loading = ref(true);

    const data = ref([]);
    const expandedRowKeys = ref<any>([]);

    const upMemberMaskId = ref(0);

    const formRef = ref();

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //是否显示关系转移
    const bindChVisible = ref(false);
    const bcupMemberMaskId = ref(0);
    const bcupMemberId = ref(0);
    const bcMemberMaskId = ref(0);
    const bcMemberId = ref(0);

    const defaultExpandAllRows = ref(false);

    const updIdentityVisible = ref(false);
    const updIdentityMemeberId = ref(0);
    const updIdentityClubId = ref(0);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get(`us_club/${props.clubId}/member_tree`, {
        ...search.relData,
      });

      data.value = result.data.treeData;

      // expandedRowKeys.value = result.data.expendIds;

      loading.value = false;
    };

    //点击编辑按钮
    const showMemberAdd = (row: any) => {
      upMemberMaskId.value = row.memberMaskId;
      nextTick(() => {
        visible.value = true;
      });
    };

    //显示关系转移
    const showBindChange = (row: any) => {
      bcupMemberMaskId.value = row.upMemberMaskId;
      bcupMemberId.value = row.upMemberId;
      bcMemberId.value = row.memberId;
      bcMemberMaskId.value = row.memberMaskId;
      nextTick(() => {
        bindChVisible.value = true;
      });
    };

    //点击搜索按钮
    const onSearch = () => {
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    //删除
    const del = (row: any) => {
      showConfirm({
        title: "解除确认",
        content: "将解除该成员，及其下级代理/成员和本俱乐部的绑定吗，确定解除吗?",
        onOk: async () => {
          await http.delete(`us_club/${props.clubId}/member/${row.memberId}`);
          getList();
          message.success("解绑成功");
        },
      });
    };

    //监听展开事件
    const handleExpanded = (expend: boolean, record: any) => {
      if (expend) {
        expandedRowKeys.value.push(record.memberId);
      } else {
        const index = expandedRowKeys.value.findIndex((item: any) => {
          return item == record.memberId;
        });
        expandedRowKeys.value.splice(index, 1);
      }
    };

    //点击显示更改成员身份
    const showUpdIdentity = (row: any) => {
      updIdentityMemeberId.value = row.memberMaskId;
      updIdentityClubId.value = props.clubId;
      nextTick(() => {
        updIdentityVisible.value = true;
      });
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      formRef,
      loading,
      clubMemberIdentitys,
      defaultExpandAllRows,
      showMemberAdd,
      upMemberMaskId,
      del,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      getList,
      expandedRowKeys,
      ClubMemberIdentityPresident,
      bindChVisible,
      bcupMemberMaskId,
      bcupMemberId,
      bcMemberId,
      bcMemberMaskId,
      ClubMemberIdentityMember,
      showBindChange,
      handleExpanded,
      updIdentityVisible,
      updIdentityMemeberId,
      updIdentityClubId,
      showUpdIdentity,
    };
  },
});
